import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { get, forEach, upperFirst, map, isEmpty, orderBy, filter } from 'lodash'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import cx from 'classnames'
import qs from 'qs'
import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tippy'

// atoms
import DropZoneField from '../../../atoms/DropZoneField'
import Input from '../../../atoms/Input'

// components
import ElementFailure from '../../../components/ElementFailure'
import ElementEmptyContent from '../../../components/ElementEmptyContent'
import ElementLoading from '../../../components/ElementLoading'
import ArchivaciaEmailDetail from '../../../components/Modals/ArchivaciaEmailDetail'
import DefaultModal from '../../../components/Modals/DefaultModal'

// containers
import UkonZmenaUdajovOP from '../UkonZmenaUdajovOP'
import UkonZmenaUdajovZU from '../UkonZmenaUdajovZU'
import UkonZmenaRozpisovZaloh from '../UkonZmenaRozpisovZaloh'
import UkonKonzultaciaFaktury from '../UkonKonzultaciaFaktury'
import UkonKonzultaciaUpomienky from '../UkonKonzultaciaUpomienky'
import UkonKonzultaciaUctovnehoDokladu from '../UkonKonzultaciaUctovnehoDokladu'
import UkonDuplikatFaktury from '../UkonDuplikatFaktury'
import UkonDuplikatUpomienky from '../UkonDuplikatUpomienky'
import UkonDuplikatUctovnehoDokladu from '../UkonDuplikatUctovnehoDokladu'
import UkonDuplikatSEPAMandatu from '../UkonDuplikatSEPAMandatu'
import UkonVseobecnyPasivny from '../UkonVseobecnyPasivny'
import UkonVseobecnyAktivny from '../UkonVseobecnyAktivny'
import UkonZmenaUdajovMS from '../UkonZmenaUdajovMS'
import UkonKonzultaciaPlatby from '../UkonKonzultaciaPlatby'
import UkonZmenaZasielaniaFaktur from '../UkonZmenaZasielaniaFaktur'
import UkonZmenaSposobuPlatby from '../UkonZmenaSposobuPlatby'
import UkonPodpisSEPAMandatu from '../UkonPodpisSEPAMandatu'
import UkonSplnomocnenie from '../UkonSplnomocnenie'
import UkonSplnomocnenieUkoncenie from '../UkonSplnomocnenieUkoncenie'
import UkonPlanSplatok from '../UkonPlanSplatok'
import UkonVrateniePreplatkov from '../UkonVrateniePreplatkov'
import UkonBlokovanieUpominania from '../UkonBlokovanieUpominania'
import UkonZmenaNastaveniZU from '../UkonZmenaNastaveniZU'
import UkonSystemoveListy from '../UkonSystemoveListy'
import UkonGeneric from '../UkonGeneric'
import UkonZalozenieOP from '../UkonZalozenieOP'
import UkonVseobecnyAnonymny from '../UkonVseobecnyAnonymny'
import UkonDetailEditInfo from './UkonDetailEditInfo'
import UkonOverenieUdajovOP from '../UkonOverenieUdajovOP'

// utils
import { history } from '../../../utils/history'
import {
	FILTER_SELECTORS,
	DOKUMENT_TYP,
	DOCUMENT_CONTENT_TYPE,
	UKON_STAV,
	BUSINESS_CHANNELS,
	UKONY_CISELNIK,
	STORNO_DOVOD,
	MIGRACIA_UKONY_CISELNIK
} from '../../../utils/enums'
import { deleteReq, getReq, postReq } from '../../../utils/request'
import { formatDateWithTime } from '../../../utils/date'
import { openDataUriWindow } from '../../../utils/files'
import Permissions, { checkPermissions, getRequiredPermissionsForUkonRead, getRequiredPermissionsForUkonWrite } from '../../../utils/permissionsHoc'
import { setRouteParams, PREHLAD, GENERIC_UKON } from '../../../utils/routes'
import { isPodpisovyUkon } from '../../../utils/ukony'
import { getIsMop } from '../../../utils/obchodnyPartner'

class UkonDetailPage extends React.Component {
	static propTypes = {
		auth: PropTypes.shape().isRequired,
		interakcia: PropTypes.shape({
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		computedMatch: PropTypes.shape({
			params: PropTypes.shape({
				opCislo: PropTypes.any.isRequired,
				ukonCislo: PropTypes.any.isRequired
			}).isRequired
		}).isRequired,
		t: PropTypes.func.isRequired,
		selectedFilters: PropTypes.shape(),
		ciselniky: PropTypes.shape(),
		cesCiselniky: PropTypes.shape(),
		obchodnyPartner: PropTypes.shape({
			data: PropTypes.shape()
		}).isRequired
	}

	constructor(props) {
		super(props)

		const { backUrl } = qs.parse(window.location.search, {
			ignoreQueryPrefix: true
		})

		let backUrlLink = null
		if (backUrl) {
			backUrlLink = atob(backUrl)
		}

		this.state = {
			isLoading: false,
			isFailure: false,
			ukonInterakcia: null,
			ukon: null,
			ukonDataPred: null,
			ukonData: null,
			documents: null,
			inputDocumentsForUpload: [],
			otherDocumentsForUpload: [],
			inputDocumentIsLoading: false,
			otherDocumentIsLoading: false,
			archivaciaEmailDetail: null,
			showMissingPermissionsModal: false,
			showDeleteFileModal: false,
			fileToDelete: null,
			backUrlLink,
			showStornoModal: false,
			showStornoResultModal: false,
			stornoPopis: null,
			stornoDovod: STORNO_DOVOD.STORNO_POUZIVATELOM,
			stornoResult: null,
			processingStorno: false,
			canEdit: false
		}
	}

	async componentDidMount() {
		const { auth } = this.props

		const ukon = await this.loadUkon()
		const permissions = getRequiredPermissionsForUkonRead(ukon)
		if (!isEmpty(permissions) && !checkPermissions(get(auth, 'user.roles', []), permissions)) {
			return this.setState({
				showMissingPermissionsModal: true
			})
		}
	}

	async componentDidUpdate() {
		const { auth, computedMatch } = this.props
		const { ukon, isLoading } = this.state

		if (!isLoading && get(ukon, 'id') && get(ukon, 'id') != get(computedMatch, 'params.ukonCislo')) {
			const ukon = await this.loadUkon()
			this.setState({
				inputDocumentsForUpload: [],
				otherDocumentsForUpload: []
			})
			const permissions = getRequiredPermissionsForUkonRead(ukon)
			if (!isEmpty(permissions) && !checkPermissions(get(auth, 'user.roles', []), permissions)) {
				return this.setState({
					showMissingPermissionsModal: true
				})
			}
		}
	}

	loadUkon = async () => {
		try {
			const { computedMatch, obchodnyPartner } = this.props

			this.setState({
				isLoading: true
			})

			const cisloOP = get(computedMatch, 'params.cisloOP')
			const ukonCislo = get(computedMatch, 'params.ukonCislo')

			// load ukon detail data
			const res = await getReq(`/api/v2/op/${cisloOP}/ukony/${ukonCislo}`)
			// load detail interakcie
			const ukonInterakciaId = get(res, 'response.content.interakciaId')
			let resInterakcia
			if (ukonInterakciaId) {
				resInterakcia = await getReq(`/api/v0/interakcie/${ukonInterakciaId}`)
			}

			const platcaOpCislo = get(res, 'response.content.data.platcaOp.cislo')
			let platcaOpResponse
			let platcaOp
			if (platcaOpCislo) {
				platcaOpResponse = await getReq(`/api/v0/obchodni-partneri/${platcaOpCislo}`)
				const tituly = get(platcaOpResponse, 'response.obsah.tituly', [])
				const titulyPredMenom = filter(tituly, (op) => get(op, 'hodnota.pred_menom'))
				const titulyZaMenom = filter(tituly, (op) => !get(op, 'hodnota.pred_menom'))
				const orderedTitulyPredMenom = orderBy(titulyPredMenom, ['poradie'], ['asc'])
				const orderedTitulyZaMenom = orderBy(titulyZaMenom, ['poradie'], ['asc'])
				platcaOp = {
					...get(platcaOpResponse, 'response.obsah', {}),
					titulyPredMenom: map(orderedTitulyPredMenom, (op) => get(op, 'hodnota.nazov')).join(' '),
					titulyZaMenom: map(orderedTitulyZaMenom, (op) => get(op, 'hodnota.nazov')).join(' ')
				}
			}

			const ukon = get(res, 'response.content') || null

			const canEdit = !getIsMop(get(obchodnyPartner, 'data.skupinaOpravneni')) && !Object.values(MIGRACIA_UKONY_CISELNIK).includes(get(ukon, 'typ.id'))

			this.setState({
				isLoading: false,
				isFailure: false,
				ukonInterakcia: get(resInterakcia, 'response.obsah', null),
				attributes: get(res, 'response.content.atributy'),
				ukon: get(res, 'response.content'),
				ukonData: {
					...get(res, 'response.content.data'),
					platcaOp
				},
				ukonDataPred: {
					...get(res, 'response.content.dataPred')
				},
				log: get(res, 'response.content.log'),
				documents: get(res, 'response.content.dokumenty', []),
				canEdit
			})

			return ukon
		} catch (e) {
			this.setState({
				isLoading: false,
				isFailure: true,
				ukon: null,
				ukonInterakcia: null,
				ukonDataPred: null,
				ukonData: null,
				documents: null
			})
		}
	}

	confirmStorno = async () => {
		const { ukon, stornoPopis, stornoDovod } = this.state

		let stornoResult = false

		try {
			this.setState({
				processingStorno: true
			})

			await postReq(`/api/v2/ukony/${get(ukon, 'id')}/storno`, null, {
				popis: stornoPopis,
				stornoDovod
			})

			stornoResult = true
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(`Error, can not storno ukon: ${get(ukon, 'id')}`, error)
		} finally {
			this.setState({
				stornoResult,
				showStornoResultModal: true,
				stornoPopis: null,
				stornoDovod: STORNO_DOVOD.STORNO_POUZIVATELOM,
				showStornoModal: false,
				processingStorno: false
			})
		}
	}

	isStornoEnabled = () => {
		const { ukon } = this.state
		const { auth } = this.props

		const ukonId = get(ukon, 'typ.id')
		// CP-3031 - Zablokovať tlačidlo "Stornovať" pre rolu ZL na úkone podpisu zmluvy
		if (isPodpisovyUkon(ukonId) && get(auth, 'businessChannel.actual.id') === BUSINESS_CHANNELS.ZAKAZNICKA_LINKA) {
			return false
		}

		if (get(ukon, 'stornovatelny') === true) {
			return true
		}

		return false
	}

	setDocumentIsLoading = (isLoading, documentType) => {
		if (documentType === DOKUMENT_TYP.VSTUPNY) {
			this.setState({
				inputDocumentIsLoading: isLoading
			})
		}
		if (documentType === DOKUMENT_TYP.OSTATNE) {
			this.setState({
				otherDocumentIsLoading: isLoading
			})
		}
	}

	uploadFiles = async (documentsForUpload, documentType) => {
		const { ukon } = this.state

		if (isEmpty(documentsForUpload)) {
			return
		}

		this.setDocumentIsLoading(true, documentType)

		try {
			const documentsPromises = map(documentsForUpload, (file) => {
				const body = {
					contentType: get(file, 'type'),
					nazov: get(file, 'name'),
					data: get(file, 'dataAsBase64'),
					typ: {
						id: documentType
					}
				}

				return postReq(`/api/v0/ukony/${get(ukon, 'id')}/prilozit-dokument`, null, body)
			})

			await Promise.all(documentsPromises)
			this.loadUkon()
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		} finally {
			this.setDocumentIsLoading(false, documentType)
			if (documentType === DOKUMENT_TYP.VSTUPNY) {
				this.setState({
					inputDocumentsForUpload: []
				})
			}
			if (documentType === DOKUMENT_TYP.OSTATNE) {
				this.setState({
					otherDocumentsForUpload: []
				})
			}
		}
	}

	onDeleteFile = async () => {
		const { ukon, fileToDelete } = this.state
		this.setDocumentIsLoading(true, get(fileToDelete, 'typ.id'))

		try {
			await deleteReq(`/api/v2/ukony/${get(ukon, 'id')}/dokumenty/${get(fileToDelete, 'id')}`)
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		} finally {
			this.setDocumentIsLoading(false, get(fileToDelete, 'typ.id'))
			this.setState({
				fileToDelete: null
			})
			this.loadUkon()
		}
	}

	dropZoneField = (documentsForUpload, documentType, onChange) => {
		const { t } = this.props
		const { canEdit } = this.state

		return (
			<div>
				<DropZoneField
					meta={{}}
					input={{
						value: documentsForUpload,
						onChange
					}}
					disabled={!canEdit}
				/>
				<div className='text-right'>
					<button
						className={cx('button', { disabled: isEmpty(documentsForUpload) })}
						data-color='blue'
						type='button'
						onClick={() => {
							this.uploadFiles(documentsForUpload, documentType)
						}}
					>
						{t('translation:Common.Nahrať')}
					</button>
				</div>
			</div>
		)
	}

	formatDocuments = (documents, inputDocumentsForUpload, otherDocumentsForUpload) => {
		const { t } = this.props
		const { inputDocumentIsLoading, otherDocumentIsLoading, showDeleteFileModal, fileToDelete } = this.state

		let uploadInputFilesContainer
		let uploadOtherFilesContainer

		uploadInputFilesContainer = this.dropZoneField(inputDocumentsForUpload, DOKUMENT_TYP.VSTUPNY, (inputDocumentsForUpload) =>
			this.setState({ inputDocumentsForUpload })
		)
		uploadOtherFilesContainer = this.dropZoneField(otherDocumentsForUpload, DOKUMENT_TYP.OSTATNE, (otherDocumentsForUpload) =>
			this.setState({ otherDocumentsForUpload })
		)

		const othersDocumentsRows = []
		const inputDocumentsRows = []
		const outputDocumentsRows = []

		forEach(documents, (document) => {
			const dokumentTypID = get(document, 'typ.id')
			const showDeleteBtn = dokumentTypID == DOKUMENT_TYP.OSTATNE || dokumentTypID == DOKUMENT_TYP.VSTUPNY
			const doc = (
				<tr key={get(document, 'id')}>
					<td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '5px' }}>
						<a
							className='file file-name'
							onClick={() => openDataUriWindow(get(document, 'id'), get(document, 'nazov'))}
							data-type='general'
							style={{ cursor: 'pointer' }}
						>
							<span style={{ wordBreak: 'break-all' }}>{get(document, 'nazov')}</span>
						</a>
						{showDeleteBtn && (
							<Tooltip html={<span>{t('translation:Common.Odstrániť')}</span>} position='bottom' trigger='mouseenter' theme='light'>
								<a
									style={{ display: 'block' }}
									className='remove-file-btn'
									onClick={() => {
										this.setState({
											showDeleteFileModal: true,
											fileToDelete: document
										})
									}}
								/>
							</Tooltip>
						)}
					</td>
				</tr>
			)

			if (dokumentTypID == DOKUMENT_TYP.VYSTUPNY || dokumentTypID == DOKUMENT_TYP.UKONCENY || dokumentTypID == DOKUMENT_TYP.STORNO) {
				outputDocumentsRows.push(doc)
			} else if (dokumentTypID == DOKUMENT_TYP.OSTATNE) {
				othersDocumentsRows.push(doc)
			} else {
				inputDocumentsRows.push(doc)
			}
		})

		const modalContent = (
			<div style={{ wordBreak: 'break-all' }}>
				{t('translation:Common.Naozaj chcete súbor {filename} odstrániť?', { filename: get(fileToDelete, 'nazov') })}
			</div>
		)

		const loadingState = (
			<div style={{ position: 'relative', height: '100px' }}>
				<ElementLoading />
			</div>
		)

		const emptyState = (
			<div className='upload-empty-state'>
				<div>
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<div className='empty-icon' />
					</div>
					<p>{t('translation:Common.Žiadne dokumenty')}</p>
				</div>
			</div>
		)

		return (
			<>
				<DefaultModal
					modalTitle={t('translation:Common.Upozornenie!')}
					modalContent={modalContent}
					rightButton={{
						onClick: () => {
							this.setState({
								showDeleteFileModal: false
							})
						},
						text: t('translation:Common.Späť'),
						color: 'blue'
					}}
					leftButton={{
						onClick: async () => {
							this.setState({
								showDeleteFileModal: false
							})
							await this.onDeleteFile()
						},
						text: t('translation:Common.Odstrániť'),
						color: 'red',
						outline: true
					}}
					visible={showDeleteFileModal}
				/>
				<div className='row' style={{ marginTop: '10px' }}>
					<div className='col-6'>
						<div className='inner-box-with-border'>
							<h4>{t('translation:Common.Vstupné dokumenty')}</h4>
							{inputDocumentIsLoading && <>{loadingState}</>}
							{!inputDocumentIsLoading && (
								<>
									{isEmpty(inputDocumentsRows) && <>{emptyState}</>}
									{!isEmpty(inputDocumentsRows) && (
										<table className='content-table bordered' cellSpacing='0'>
											<tbody>{inputDocumentsRows}</tbody>
										</table>
									)}
									{uploadInputFilesContainer}
								</>
							)}
						</div>
					</div>

					<div className='col-6'>
						<div className='inner-box-with-border'>
							<h4>{t('translation:Common.Výstupné dokumenty')}</h4>
							{isEmpty(outputDocumentsRows) && <>{emptyState}</>}
							{!isEmpty(outputDocumentsRows) && (
								<table className='content-table bordered' cellSpacing='0'>
									<tbody>{outputDocumentsRows}</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
				<div className='row' style={{ marginTop: '10px' }}>
					<div className='col-6'>
						<div className='inner-box-with-border'>
							<h4>{t('translation:Common.Ostatné dokumenty')}</h4>
							{otherDocumentIsLoading && <>{loadingState}</>}
							{!otherDocumentIsLoading && (
								<>
									{isEmpty(othersDocumentsRows) && <>{emptyState}</>}
									{!isEmpty(othersDocumentsRows) && (
										<table className='content-table bordered striped' cellSpacing='0'>
											<tbody>{othersDocumentsRows}</tbody>
										</table>
									)}
									{uploadOtherFilesContainer}
								</>
							)}
						</div>
					</div>
				</div>
			</>
		)
	}

	render() {
		const {
			isLoading,
			isFailure,
			attributes,
			ukon,
			ukonData,
			ukonDataPred,
			documents,
			ukonInterakcia,
			log,
			archivaciaEmailDetail,
			showMissingPermissionsModal,
			backUrlLink,
			showStornoModal,
			showStornoResultModal,
			stornoPopis,
			stornoDovod,
			stornoResult,
			processingStorno,
			inputDocumentsForUpload,
			otherDocumentsForUpload,
			canEdit
		} = this.state
		const { auth, interakcia, t, cesCiselniky } = this.props

		if (isLoading) {
			return <ElementLoading />
		}
		if (isFailure) {
			return <ElementFailure text={t('translation:Common.Nepodarilo sa načítať detail úkonu')} />
		}
		if (!ukon) {
			return <ElementEmptyContent text={t('translation:Common.Úkon sa nenašiel')} />
		}

		if (showMissingPermissionsModal) {
			return (
				<div className='content-wrapper'>
					<DefaultModal
						modalTitle={t('translation:Common.Chyba')}
						modalContent={t('translation:Common.Na vykonanie akcie nemáte potrebné oprávnenia')}
						leftButton={{
							onClick: () => history.replace(setRouteParams(PREHLAD, get(interakcia, 'opCislo'))),
							text: t('translation:Common.Zavrieť'),
							color: 'red'
						}}
						visible
					/>
				</div>
			)
		}

		let odlozenyUkonAction = null
		let ukonDataEmpty = null
		let ukonDataActiveRows = null
		let ukonDataPasiveRows = null

		const logRows = map(log, (item, index) => {
			const attachments = map(get(item, 'dokumenty', []), (attachment, attachmentIndex) => {
				let onClick
				if (get(attachment, 'contentType') == DOCUMENT_CONTENT_TYPE.ZSEE_EMAIL) {
					onClick = () =>
						this.setState({
							archivaciaEmailDetail: get(attachment, 'id')
						})
				} else {
					onClick = () => openDataUriWindow(get(attachment, 'id'), get(attachment, 'nazov'))
				}
				return (
					<a
						key={`log-${index}-attachment-${attachmentIndex}`}
						className='file'
						onClick={onClick}
						data-type='general'
						style={{
							cursor: 'pointer',
							marginRight: '20px'
						}}
					>
						{get(attachment, 'nazov')}
					</a>
				)
			})
			return (
				<tr key={`logy-${index}`}>
					<td>{formatDateWithTime(get(item, 'ts'))}</td>
					<td>{get(item, 'stav.nazov', '-')}</td>
					<td style={{ wordBreak: 'break-all' }}>{get(item, 'riesitel', '-')}</td>
					<td>
						<div>{get(item, 'komentar', '-')}</div>
						{!isEmpty(attachments) && <div>{attachments}</div>}
					</td>
				</tr>
			)
		})

		const header = (
			<UkonDetailEditInfo
				auth={auth}
				ukon={ukon}
				ukonData={ukonData}
				ukonDataPred={ukonDataPred}
				ukonInterakcia={ukonInterakcia}
				loadUkon={this.loadUkon}
				canEdit={canEdit}
			/>
		)

		if (ukonData) {
			const ukonType = get(ukon, 'typ.id', -1)
			switch (ukonType) {
				case 1:
					ukonDataActiveRows = (
						<UkonZmenaUdajovOP
							t={t}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 2:
					ukonDataActiveRows = (
						<UkonZmenaUdajovZU
							t={t}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 3:
					ukonDataActiveRows = (
						<UkonZmenaRozpisovZaloh
							t={t}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 4:
					ukonDataPasiveRows = (
						<UkonKonzultaciaFaktury
							t={t}
							ukonData={ukonData}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 5:
					ukonDataPasiveRows = (
						<UkonVseobecnyPasivny
							t={t}
							ukonData={ukonData}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 6:
					ukonDataPasiveRows = (
						<UkonVseobecnyAktivny
							t={t}
							ukonData={ukonData}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 7:
					ukonDataPasiveRows = (
						<UkonKonzultaciaPlatby
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 8:
					ukonDataPasiveRows = (
						<UkonKonzultaciaUpomienky
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 9:
					ukonDataActiveRows = (
						<UkonZmenaUdajovMS
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 10:
					ukonDataActiveRows = (
						<UkonZmenaZasielaniaFaktur
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 11:
					ukonDataActiveRows = (
						<UkonZmenaSposobuPlatby
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 12:
					ukonDataPasiveRows = (
						<UkonSplnomocnenie
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 13:
				case 20:
				case 21:
					ukonDataPasiveRows = (
						<UkonPlanSplatok
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 14:
					ukonDataPasiveRows = (
						<UkonDuplikatFaktury
							t={t}
							ukonData={ukonData}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 15:
					return (
						<UkonPodpisSEPAMandatu
							logRows={logRows}
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
							header={header}
							loadUkon={this.loadUkon}
						/>
					)
				case 16:
					ukonDataActiveRows = (
						<UkonSplnomocnenieUkoncenie
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 17:
					ukonDataPasiveRows = (
						<UkonDuplikatUpomienky
							t={t}
							ukonData={ukonData}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 18:
					ukonDataPasiveRows = (
						<UkonZalozenieOP
							t={t}
							ukonData={ukonData}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 19:
					ukonDataPasiveRows = (
						<UkonDuplikatSEPAMandatu
							t={t}
							ukonData={ukonData}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 22:
					ukonDataPasiveRows = (
						<UkonKonzultaciaUctovnehoDokladu
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 23:
					ukonDataPasiveRows = (
						<UkonDuplikatUctovnehoDokladu
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 24:
					ukonDataPasiveRows = (
						<UkonVrateniePreplatkov
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 25:
					ukonDataPasiveRows = (
						<UkonBlokovanieUpominania
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 28:
					ukonDataActiveRows = (
						<UkonZmenaNastaveniZU
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
							cesCiselniky={cesCiselniky}
						/>
					)
					break
				case 29:
					ukonDataPasiveRows = (
						<UkonSystemoveListy
							t={t}
							ukonData={ukonData}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case UKONY_CISELNIK.ANONYMNY_VSEOBECNY_UKON:
					ukonDataPasiveRows = (
						<UkonVseobecnyAnonymny
							t={t}
							ukonData={ukonData}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 48:
					ukonDataActiveRows = (
						<UkonZmenaNastaveniZU
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
							cesCiselniky={cesCiselniky}
						/>
					)
					break
				case 49:
					ukonDataActiveRows = (
						<UkonZmenaUdajovOP
							t={t}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				case 51:
					ukonDataActiveRows = (
						<UkonOverenieUdajovOP
							t={t}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
						/>
					)
					break
				default: {
					if (get(ukon, 'ukonStav.id') == UKON_STAV.ODLOZENY) {
						// NOTE: CP-2107
						if (
							[
								UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE,
								UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS,
								UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_NOVY_ODBER,
								UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_OBNOVA_MS,
								UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_DODATOK,
								UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_VAS,
								UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS_GAS,
								UKONY_CISELNIK.PODPIS_ZMLUVY_GAS_NOVY_ODBER,
								UKONY_CISELNIK.PODPIS_ZMLUVY_GAS_OBNOVA_MS
							].indexOf(get(ukon, 'typ.id')) < 0 ||
							get(auth, 'businessChannel.actual.id') != BUSINESS_CHANNELS.ZAKAZNICKA_LINKA
						) {
							odlozenyUkonAction = (
								<Link
									className='button pull-right'
									data-color='blue'
									style={{ marginLeft: '20px' }}
									to={{
										pathname: setRouteParams(GENERIC_UKON, get(interakcia, 'opCislo')),
										ponuka: {
											nazov: upperFirst(get(ukon, 'typ.nazov')),
											ukon,
											atributy: get(ukon, 'atributy', [])
										}
									}}
								>
									{upperFirst(get(ukon, 'typ.nazov'))}
								</Link>
							)
						}
					}
					if (!isEmpty(ukonDataPred)) {
						ukonDataActiveRows = (
							<UkonGeneric
								t={t}
								attributes={attributes}
								ukon={ukon}
								ukonData={ukonData}
								ukonDataPred={ukonDataPred}
								formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
							/>
						)
					} else {
						ukonDataPasiveRows = (
							<UkonGeneric
								t={t}
								attributes={attributes}
								ukon={ukon}
								ukonData={ukonData}
								formatDocuments={this.formatDocuments(documents, inputDocumentsForUpload, otherDocumentsForUpload)}
							/>
						)
					}
				}
			}
		} else {
			ukonDataEmpty = <ElementEmptyContent text={t('translation:Common.Úkon neobsahuje žiadne dáta')} />
		}

		let modal = null
		if (archivaciaEmailDetail) {
			modal = (
				<ArchivaciaEmailDetail
					modalTitle={t('translation:Common.Archivovaný email')}
					onCloseButton={() =>
						this.setState({
							archivaciaEmailDetail: null
						})
					}
					id={archivaciaEmailDetail}
				/>
			)
		} else if (showStornoResultModal) {
			modal = (
				<DefaultModal
					modalTitle={stornoResult ? t('translation:Common.Odoslané') : t('translation:Common.Chyba')}
					modalContent={
						stornoResult ? t('translation:Common.Storno úkonu bolo úspešne odoslané') : t('translation:Common.Storno úkonu sa nepodarilo odoslať')
					}
					leftButton={{
						onClick: () => {
							this.setState({
								showStornoResultModal: false,
								stornoResult: false
							})
							this.loadUkon()
						},
						text: t('translation:Common.Zavrieť'),
						color: stornoResult ? 'green' : 'red'
					}}
					visible={showStornoResultModal}
				/>
			)
		}

		const stornoModal = (
			<DefaultModal
				modalTitle={t('translation:Common.Naozaj chcete úkon stornovať?')}
				modalContent={
					<>
						<div className='row'>
							<div className='col-12'>
								<Input
									placeholder={t('translation:Common.Uveďte dôvod')}
									onChange={(e) => {
										this.setState({
											stornoPopis: get(e, 'target.value')
										})
									}}
									maxLength={200}
									required
									error={isEmpty(stornoPopis)}
									disabled={processingStorno}
								/>
							</div>
						</div>
						<div className='row'>
							<div className='col-6'>
								<button
									className='button'
									type='button'
									onClick={() =>
										this.setState({
											stornoDovod: STORNO_DOVOD.STORNO_POUZIVATELOM
										})
									}
									data-color='blue'
									data-type={stornoDovod != STORNO_DOVOD.STORNO_POUZIVATELOM ? 'outline' : ''}
									style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
								>
									{t('translation:Common.S notifikáciou')}
								</button>
							</div>
							<div className='col-6'>
								<button
									className='button'
									type='button'
									onClick={() =>
										this.setState({
											stornoDovod: STORNO_DOVOD.STORNO_POUZIVATELOM_BEZ_NOTIFIKACIE
										})
									}
									data-color='blue'
									data-type={stornoDovod != STORNO_DOVOD.STORNO_POUZIVATELOM_BEZ_NOTIFIKACIE ? 'outline' : ''}
									style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
								>
									{t('translation:Common.Bez notifikácie')}
								</button>
							</div>
						</div>
					</>
				}
				leftButton={{
					onClick: () => {
						this.setState({
							stornoPopis: null,
							showStornoModal: false
						})
					},
					text: t('translation:Common.Naspäť'),
					color: 'red',
					outline: true,
					disabled: processingStorno
				}}
				rightButton={{
					onClick: this.confirmStorno,
					text: t('translation:Common.Pokračovať'),
					color: 'blue',
					disabled: processingStorno || isEmpty(stornoPopis)
				}}
				visible={showStornoModal}
			/>
		)

		const stornoEnabled = this.isStornoEnabled()
		const writePermissions = getRequiredPermissionsForUkonWrite(ukon)

		return (
			<>
				{(backUrlLink || stornoEnabled || odlozenyUkonAction) && (
					<div className='content-header clearfix'>
						{backUrlLink && (
							<Link to={`${backUrlLink}`} className='button pull-left' data-type='back-button' data-color='blue'>
								{t('containers:Späť')}
							</Link>
						)}
						{stornoEnabled && (
							<Permissions
								allowed={writePermissions}
								render={(hasPerm) => {
									if (hasPerm) {
										return (
											<>
												<button
													className='button pull-right'
													data-color='blue'
													style={{ marginLeft: '20px' }}
													onClick={() => {
														this.setState({
															stornoPopis: null,
															showStornoModal: true
														})
													}}
												>
													{t('translation:Common.Stornovať')}
												</button>
												{stornoModal}
											</>
										)
									}
									return (
										<Tooltip
											className='pull-right'
											html={<span>{t('translation:Common.Na vykonanie akcie nemáte potrebné oprávnenia')}</span>}
											position='top'
											trigger='mouseenter'
											theme='light'
										>
											<button
												className={cx('button', 'pull-right', { disabled: true })}
												data-color='blue'
												style={{ marginLeft: '20px' }}
												onClick={(e) => e.preventDefault()}
											>
												{t('translation:Common.Stornovať')}
											</button>
										</Tooltip>
									)
								}}
							/>
						)}
						{odlozenyUkonAction}
					</div>
				)}
				<div className='content-wrapper'>
					{modal}
					{header}
					{ukonDataActiveRows && (
						<div className='box'>
							<div className='box-header'>{t('translation:Common.Detaily úkonu')}</div>
							<div className='box-content'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<thead>
										<tr>
											<th />
											<th>{t('translation:Common.Pôvodné hodnoty')}</th>
											<th>{t('translation:Common.Nové hodnoty')}</th>
										</tr>
									</thead>
									{ukonDataActiveRows}
								</table>
							</div>
						</div>
					)}
					{ukonDataPasiveRows && (
						<div className='box'>
							<div className='box-header'>{t('translation:Common.Detaily úkonu')}</div>
							<div className='box-content'>
								<table className='content-table padded bordered' cellSpacing='0'>
									{ukonDataPasiveRows}
								</table>
							</div>
						</div>
					)}
					{ukonDataEmpty && (
						<div className='box'>
							<div className='box-header'>{t('translation:Common.Detaily úkonu')}</div>
							<div className='box-content' style={{ minHeight: '200px' }}>
								{ukonDataEmpty}
							</div>
						</div>
					)}
					{!isEmpty(logRows) && (
						<div className='box'>
							<div className='box-header'>{t('translation:Common.Logy')}</div>
							<div className='box-content' style={{ minHeight: '200px' }}>
								<table className='content-table padded bordered' cellSpacing='0'>
									<thead>
										<tr>
											<th style={{ width: '180px' }}>{t('translation:Common.Dátum')}</th>
											<th style={{ width: '220px' }}>{t('translation:Common.Stav')}</th>
											<th style={{ width: '300px' }}>{t('translation:Common.Riešiteľ')}</th>
											<th>{t('translation:Common.Komentár')}</th>
										</tr>
									</thead>
									<tbody>{logRows}</tbody>
								</table>
							</div>
						</div>
					)}
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth'),
	interakcia: get(state, 'interakcie.detail.data'),
	selectedFilters: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`),
	ciselniky: get(state, 'ciselniky.data'),
	cesCiselniky: get(state, 'cesCiselniky.data'),
	obchodnyPartner: state.obchodnyPartner.detail
})

export default compose(withTranslation('containers'), connect(mapStateToProps))(UkonDetailPage)
