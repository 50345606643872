// noinspection JSUnusedAssignment

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Link } from 'react-router-dom'
import { map, get, isEmpty, isEqual } from 'lodash'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import cx from 'classnames'
import { withTranslation } from 'react-i18next'
import { isValid, getFormValues, initialize, destroy } from 'redux-form'
import { Tooltip } from 'react-tippy'

// components
import ElementLoading from '../components/ElementLoading'
import ElementFailure from '../components/ElementFailure'
import DefaultModal from '../components/Modals/DefaultModal'
import GenericUkonForm, { GENERIC_FIELDS_KEY } from '../components/GenericUkon/VseobecnyPasivnyUkon/GenericUkonForm'
import { UKON_CATEGORY_ONE_KEY, UKON_TIME_RANGE_KEY } from '../components/GenericUkon/VseobecnyPasivnyUkon/GenericFieldArray'
import { ConditionalWrapper } from '../components/ConditionalWrapper'
import UkoncenieExternejIntegracie from '../components/Modals/UkoncenieExternejIntegracie'

// actions
import InterakcieActions from '../actions/Interakcie'
import * as UkonyActions from '../actions/UkonyActions'
import * as PonukaActions from '../actions/PonukaActions'
import * as TrackingActions from '../actions/TrackingActions'
import * as DigitalizaciaActions from '../actions/ObchodniPartneri/Digitalizacia'
import * as ExternalIntegrationsActions from '../actions/ExternalIntegrationsActions'

// utils
import { postReq } from '../utils/request'
import { DOKUMENT_TYP, FORMS as FORM, UKONY_CISELNIK, EXTERNAL_INTEGRATIONS } from '../utils/enums'
import { setRouteParams, PREHLAD, INDEX } from '../utils/routes'
import { getIsMop } from '../utils/obchodnyPartner'
import { history } from '../utils/history'
import { recognizeMethodologicalGuideline } from './GenericUkon/guidelineConfig'
import { formatData } from '../utils/genericUkon'

dayjs.extend(duration)

class UkoncenieInterakcie extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		interakcieActions: PropTypes.shape({
			endInteraction: PropTypes.func.isRequired
		}).isRequired,
		interakcia: PropTypes.shape({
			detail: PropTypes.shape({
				data: PropTypes.shape({
					id: PropTypes.number.isRequired,
					opCislo: PropTypes.string.isRequired
				}).isRequired,
				isLoading: PropTypes.bool.isRequired
			}).isRequired,
			isLoading: PropTypes.bool.isRequired
		}).isRequired,
		obchodnyPartner: PropTypes.shape({
			data: PropTypes.shape()
		}),
		digitalizacia: PropTypes.shape(),
		ukonyByInterakciaId: PropTypes.shape(),
		ukonyActions: PropTypes.shape(),
		trackingActions: PropTypes.shape(),
		digitalizaciaActions: PropTypes.shape(),
		tracking: PropTypes.shape(),
		auth: PropTypes.shape(),
		isFormValid: PropTypes.bool.isRequired,
		t: PropTypes.func.isRequired,
		liveAgentSession: PropTypes.string,
		finesseSession: PropTypes.string,
		call250Session: PropTypes.string
	}

	constructor(props) {
		super(props)

		this.state = {
			ukonOptions: [],
			index: 0,
			currentTime: dayjs(),
			currentTimeInterval: null,
			isSubmitting: false,
			showExternalIntegrationModal: false
		}
	}

	formatDataForUkon = (manualnyUkon) => {
		const { interakcia, auth } = this.props

		const dokumentyVstupne = map(get(manualnyUkon, 'dokumenty', []), (file) => ({
			contentType: file.type,
			nazov: file.name,
			data: file.dataAsBase64,
			typ: {
				id: DOKUMENT_TYP.VSTUPNY
			}
		}))

		return {
			trvanie: dayjs(get(manualnyUkon, `${UKON_TIME_RANGE_KEY}.endTime`)).diff(
				dayjs(get(manualnyUkon, `${UKON_TIME_RANGE_KEY}.startTime`)),
				'millisecond'
			),
			dokumenty: [...dokumentyVstupne],
			zacatyOd: dayjs(get(manualnyUkon, `${UKON_TIME_RANGE_KEY}.startTime`)).toISOString(),
			...formatData(auth, manualnyUkon, UKONY_CISELNIK.VSEOBECNY_UKON_PASIVNY, get(interakcia, 'detail.data'))
		}
	}

	sendDraftPrepare = async (manualnyUkon) => {
		const body = this.formatDataForUkon(manualnyUkon)
		// CP-3430 - Hotfix
		const result = await postReq(`/api/v2/ukony/draft-prepare`, null, body)
		return { ...body, id: get(result, 'response.content.ukon.id') }
	}

	getPrimarnyUkonId = (ukonyData) => {
		const { formValues } = this.props
		let primarnyUkonId
		if (formValues?.primaryUkonIndex != null) {
			primarnyUkonId = get(ukonyData[formValues.primaryUkonIndex], 'id') ?? undefined
		} else {
			primarnyUkonId = formValues?.primaryUkonId ?? undefined
		}
		return primarnyUkonId
	}

	submitUkony = async () => {
		const { interakcia, auth, obchodnyPartner, isFormValid, formValues } = this.props
		const { isSubmitting } = this.state

		if (isSubmitting || !isFormValid || get(interakcia, 'isLoading')) {
			return
		}

		this.setState({
			isSubmitting: true
		})

		const manualneUkony = formValues?.[GENERIC_FIELDS_KEY] ?? []

		try {
			const sendUkonyPromises = map(manualneUkony, this.sendDraftPrepare)
			const ukonyData = await Promise.all(sendUkonyPromises)

			if (!isEmpty(ukonyData)) {
				const submittedUkony = await postReq('/api/v2/ukony/spustit-zoznam', null, {
					id: get(interakcia, 'detail.data.id'),
					typ: {
						id: UKONY_CISELNIK.VSEOBECNY_UKON_PASIVNY,
						nazov: 'všeobecný pasívny úkon'
					},
					opCislo: get(obchodnyPartner, 'data.cislo'),
					riesitel: get(auth, 'user.id'),
					kanal: get(auth, 'businessChannel.actual'),
					ukony: [...(ukonyData || [])],
					primarnyUkonId: this.getPrimarnyUkonId(ukonyData)
				})
				return submittedUkony
			}
			return {}
		} catch (e) {
			// eslint-disable-next-line
			console.error(e)
		}
	}

	updateExterneIDs = (externeIDsForRequest = [], options) => {
		const { interakcia } = this.props
		const { localSessionId, source, shouldConnectExternalIntegration } = options

		const externeIDsInInterakcia = get(interakcia, 'detail.data.externeIDs')

		const sessionInInterakcia = externeIDsInInterakcia?.find((externeID) => externeID.source === source)
		if (sessionInInterakcia && shouldConnectExternalIntegration === false) {
			// Ak existuje session v interakcii, ale chceme ju zmazať (odpárovať od interakcie)
			externeIDsForRequest.push({
				source,
				externalId: null
			})
		} else if (sessionInInterakcia && sessionInInterakcia?.externalId !== localSessionId) {
			// Ak existuje session v interakcii a nezhoduje sa s lokálnou session, použijeme lokálnu
			externeIDsForRequest.push({
				source,
				externalId: localSessionId
			})
		} else if (sessionInInterakcia && sessionInInterakcia?.externalId === localSessionId) {
			// Ak existuje session v interakcii a zhoduje sa s lokálnou session, použijeme existujúcu z interakcie
			externeIDsForRequest.push(sessionInInterakcia)
		} else if (localSessionId && shouldConnectExternalIntegration) {
			// Ak neexistuje session v interakcii, ale existuje lokálna session, použijeme lokálnu
			externeIDsForRequest.push({
				externalId: localSessionId,
				source
			})
		}

		return externeIDsForRequest
	}

	clearAndRedirect = () => {
		history.push(`${INDEX}?clear=true`)
	}

	submitInteraction = async (submittedUkony, shouldConnectExternalIntegration) => {
		const { liveAgentSession, finesseSession, call250Session, interakcieActions, formValues } = this.props

		let primaryUkonId = this.getPrimarnyUkonId(submittedUkony)

		// CP-3008
		const formattedAcw = formValues.acwValue ? [{ odpoved: formValues.acwValue, kod: 'digi' }] : []
		const poznamka = formValues.poznamka ? formValues.poznamka : null

		const externeIDsForRequest = []
		if (liveAgentSession) {
			this.updateExterneIDs(externeIDsForRequest, {
				localSessionId: liveAgentSession,
				source: EXTERNAL_INTEGRATIONS.LIVE_AGENT,
				shouldConnectExternalIntegration
			})
		}
		if (finesseSession) {
			this.updateExterneIDs(externeIDsForRequest, {
				localSessionId: finesseSession,
				source: EXTERNAL_INTEGRATIONS.FINESSE,
				shouldConnectExternalIntegration
			})
		}
		if (call250Session) {
			this.updateExterneIDs(externeIDsForRequest, {
				localSessionId: call250Session,
				source: EXTERNAL_INTEGRATIONS.CALL250,
				shouldConnectExternalIntegration
			})
		}

		const endInteractionBody = {
			poznamka,
			acw: formattedAcw,
			externeIDs: externeIDsForRequest,
			primarnyUkonId: primaryUkonId
		}

		interakcieActions.endInteraction(endInteractionBody)
		this.clearAndRedirect()
	}

	// Uloží úkony a ukončí interakciu bez prepojenia na externú integráciu
	// Nevymaže externú integráciu zo session storagu
	endInteractionScenarA = async () => {
		const submittedUkony = await this.submitUkony()
		this.submitInteraction(submittedUkony, false)
	}

	// Uloží úkony a ukončí interakciu s prepojením na externú integráciu
	// Nevymaže externú integráciu zo session storagu
	endInteractionScenarB = async () => {
		const submittedUkony = await this.submitUkony()
		this.submitInteraction(submittedUkony, true)
	}

	// Uloží úkony a ukončí interakciu s prepojením na externú integráciu
	// Vymaže externú integráciu zo session storagu
	endInteractionScenarC = async () => {
		const { externalIntegrationsActions } = this.props

		const submittedUkony = await this.submitUkony()
		await this.submitInteraction(submittedUkony, true)
		externalIntegrationsActions.clearAllExternalSessions()
	}

	handleDokoncitButton = async () => {
		const { liveAgentSession, finesseSession, call250Session } = this.props

		// Ak existuje nejaká externá integrácia, zistíme ako chce používateľ ukončiť interakciu
		if (liveAgentSession || finesseSession || call250Session) {
			this.setState({ showExternalIntegrationModal: true })
			return
		}

		// Ak nie je žiadna externá integrácia, rovno ukončíme interakciu
		this.endInteractionScenarA()
	}

	async componentDidMount() {
		const { auth, ukonyActions, ponukaActions, digitalizaciaActions, dispatch, interakcia } = this.props

		// load all ukon assigned to current interakcion
		ukonyActions.loadUkonyByInteractionId()
		const currentTimeInterval = setInterval(() => {
			this.setState({
				currentTime: dayjs()
			})
		}, 1000 * 10) // every 10 seconds
		this.setState({ currentTimeInterval })

		const activeBusinessChannel = get(auth, 'businessChannel.actual.id')
		const ponuka = await ponukaActions.loadPonukaObchodnyPartner({
			kanalId: activeBusinessChannel,
			kontextId: 'ukoncenie-interakcie'
		})

		dispatch(
			initialize(
				FORM.GENERIC_UKON_UKONCIT_INTERAKCIU,
				{
					poznamka: get(interakcia, 'detail.data.poznamka', null),
					primaryUkonId: get(interakcia, 'detail.data.primarnyUkonId', null),
					primaryUkonIndex: null
				},
				true
			)
		)

		digitalizaciaActions.loadDigitalizacia()

		const options =
			ponuka?.map((option) => {
				return {
					label: option?.nazov,
					value: option?.nazov
				}
			}) || []

		this.setState({
			...this.state,
			ukonOptions: [...options]
		})
	}

	// NOTE: CP-1370
	componentDidUpdate(prevProps) {
		const { isSubmitting } = this.state
		const { interakcia } = this.props

		if (get(prevProps, 'interakcia.isLoading') && !get(interakcia, 'isLoading') && isSubmitting) {
			this.setState({
				isSubmitting: false
			})
		}
	}

	componentWillUnmount() {
		const { currentTimeInterval } = this.state
		const { dispatch } = this.props

		dispatch(destroy(FORM.GENERIC_UKON_UKONCIT_INTERAKCIU))

		if (currentTimeInterval) {
			clearInterval(currentTimeInterval)
		}
	}

	enableDokoncitButton() {
		const { formValues, isFormValid, interakcia, t, ukonyByInterakciaId } = this.props
		const { isSubmitting } = this.state

		let isUkonValid = true
		let isPrimaryUkonValid = true
		let tooltipText = ''

		if (formValues?.primaryUkonId == null && formValues?.primaryUkonIndex == null) {
			// Primárny úkon je povinné označiť len ak pridávam manuálny úkon alebo v interakcii už existuje aspoň jeden úkon
			if (formValues?.ukony?.length > 0 || ukonyByInterakciaId?.data?.length > 0) {
				isPrimaryUkonValid = false
				tooltipText = t('containers:UkoncenieInterakcie.Vyberte primárny úkon')
			}
		}

		if (!isEmpty(formValues?.ukony)) {
			isUkonValid = formValues.ukony.every((manualnyUkon) => {
				if (manualnyUkon && manualnyUkon[UKON_CATEGORY_ONE_KEY]) return true
				return false
			})
		}

		const isButtonEnabled = isFormValid && !get(interakcia, 'isLoading') && !isSubmitting && isUkonValid && isPrimaryUkonValid

		return { isButtonEnabled, tooltipText }
	}

	commonContentContainer = (content) => {
		const { interakcia, obchodnyPartner, t } = this.props
		const { currentTime, showRemoveConfirmModal, showExternalIntegrationModal } = this.state

		const interakciaStartTime = dayjs(get(interakcia, 'detail.data.vytvorenaOd')).startOf('minute')
		const currentTimeMinute = currentTime.startOf('minute')
		const interactiaDlzkaTrvania = parseInt(dayjs.duration(currentTimeMinute.diff(interakciaStartTime)).asMinutes()) // ignore float values
		const opMeno = [obchodnyPartner.data.meno || '', obchodnyPartner.data.priezvisko || ''].join(' ').trim() || null
		const interakciaLabel = opMeno ? `Interakcia ${opMeno}` : ''

		const { isButtonEnabled, tooltipText } = this.enableDokoncitButton()

		let modal
		if (get(showRemoveConfirmModal, 'visible')) {
			modal = (
				<DefaultModal
					modalTitle={t('translation:Common.Upozornenie!')}
					modalContent={t('translation:Common.Naozaj chcete úkon odstrániť?')}
					rightButton={{
						onClick: () =>
							this.setState({
								showRemoveConfirmModal: {
									visible: false,
									index: null
								}
							}),
						text: t('translation:Common.Späť'),
						color: 'blue'
					}}
					leftButton={{
						onClick: () => {
							// TODO - add remove of field in array
							// this.removeUkon(get(showRemoveConfirmModal, 'index'))
							this.setState({
								showRemoveConfirmModal: {
									visible: false,
									index: null
								}
							})
						},
						text: t('translation:Common.Odstrániť'),
						color: 'red',
						outline: true
					}}
					visible
				/>
			)
		}

		const methodologicalGuidelineLink = recognizeMethodologicalGuideline({ typ: { id: UKONY_CISELNIK.VSEOBECNY_UKON_PASIVNY } })

		return (
			<>
				{modal}
				<div className='content-header'>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<div style={{ display: 'flex' }}>
							<Link
								to={setRouteParams(PREHLAD, get(interakcia, 'detail.data.opCislo'))}
								className='button pull-left'
								data-type='back-button'
								data-color='blue'
							>
								{t('containers:Späť')}
							</Link>
							<div className='header-title'>{t('containers:UkoncenieInterakcie.Ukončenie interakcie')}</div>
							{methodologicalGuidelineLink && (
								<a href={methodologicalGuidelineLink} className='methodology-icon' target='_blank' rel='noopener noreferrer' />
							)}
						</div>
						<div>
							<ConditionalWrapper
								condition={tooltipText}
								wrapper={(children) => (
									<Tooltip html={<span>{tooltipText}</span>} position='left' trigger='mouseenter' theme='light'>
										{children}
									</Tooltip>
								)}
							>
								<button
									onClick={this.handleDokoncitButton}
									className={cx('button', { disabled: !isButtonEnabled })}
									disabled={!isButtonEnabled}
									data-color='blue'
									style={{ marginLeft: '20px' }}
								>
									{t('containers:Dokončiť')}
								</button>
							</ConditionalWrapper>
						</div>
					</div>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<div className='box-header clearfix'>
							<strong className='pull-left' data-text-size='medium'>
								{interakciaLabel}
							</strong>
							<div className='interaction-duration pull-right' style={{ marginTop: '3px' }}>
								<strong>{t('containers:UkoncenieInterakcie.Dĺžka interakcie')} </strong>
								<span style={{ fontWeight: 400 }}>
									{interakciaStartTime.format('HH:mm')} - {currentTimeMinute.format('HH:mm')} ({interactiaDlzkaTrvania} min)
								</span>
							</div>
						</div>
						{content}
						{showExternalIntegrationModal && (
							<UkoncenieExternejIntegracie
								visible={showExternalIntegrationModal}
								onEnd={this.endInteractionScenarC}
								onContinue={this.endInteractionScenarB}
								onContinueWithoutLinkId={this.endInteractionScenarA}
							/>
						)}
					</div>
				</div>
			</>
		)
	}

	render() {
		const { interakcia, ukonyByInterakciaId, obchodnyPartnerDetail, digitalizacia } = this.props
		const { isSubmitting, ukonOptions, currentTime } = this.state

		const interakciaStartTime = dayjs(get(interakcia, 'detail.data.vytvorenaOd')).startOf('minute')

		if (get(ukonyByInterakciaId, 'isLoading') || isSubmitting) {
			return this.commonContentContainer(<ElementLoading />)
		}

		if (get(ukonyByInterakciaId, 'isFailure')) {
			return this.commonContentContainer(<ElementFailure />)
		}

		const isMOP = getIsMop(get(obchodnyPartnerDetail, 'data.skupinaOpravneni'))

		return this.commonContentContainer(
			<div className='box-content'>
				<GenericUkonForm
					onSubmit={() => {}}
					interakciaStartTime={interakciaStartTime}
					currentTime={currentTime}
					ukonOptions={ukonOptions}
					isMOP={isMOP}
					digitalizacia={digitalizacia}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie,
	obchodnyPartner: state.obchodnyPartner.detail,
	ukonyByInterakciaId: state.ukony.ukonyByInterakciaId,
	tracking: state.tracking,
	auth: state.auth,
	isFormValid: isValid(FORM.GENERIC_UKON_UKONCIT_INTERAKCIU)(state),
	formValues: getFormValues(FORM.GENERIC_UKON_UKONCIT_INTERAKCIU)(state),
	digitalizacia: state.obchodnyPartner.digitalizacia,
	liveAgentSession: get(state, 'externalIntegrations.liveAgentId'),
	finesseSession: get(state, 'externalIntegrations.finesseId'),
	call250Session: get(state, 'externalIntegrations.call250Id')
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	interakcieActions: bindActionCreators(InterakcieActions, dispatch),
	ukonyActions: bindActionCreators(UkonyActions, dispatch),
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	ponukaActions: bindActionCreators(PonukaActions, dispatch),
	digitalizaciaActions: bindActionCreators(DigitalizaciaActions, dispatch),
	externalIntegrationsActions: bindActionCreators(ExternalIntegrationsActions, dispatch)
})

export default compose(withTranslation('containers'), connect(mapStateToProps, mapDispatchToProps))(UkoncenieInterakcie)
